<template>
    <div class="bg-login login-body">
        <a class="navbar-brand"><img src="../assets/images/logo.png" title="黑龙江省交通基础设施建设计划管理系统" /></a>
        <div class="box" id="login-box">
            <div class="box-content">
                <h3 class="login-tit">用户注册</h3>
                <el-form ref="form" label-width="120px" class="demo-form">

                    <el-row :gutter="0" style="margin:0">
                        <el-col :span="12">
                            <el-form-item label="用户名">
                                <el-input type="text" v-focus v-model="userInfo.userName" placeholder="请输入用户名"></el-input>
                            </el-form-item>
                            <el-form-item label="真实姓名">
                                <el-input type="text" v-focus v-model="userInfo.userTrueName" placeholder="请输入真实姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="性别">
                                <el-radio-group v-model="userInfo.gender">
                                    <el-radio :label="0">男</el-radio>
                                    <el-radio :label="1">女</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="项目类型">
                                <el-select v-model="userInfo.xmlx" placeholder="请选择项目类型" style="width:100%" @change="getCompany">
                                    <el-option v-for="item in xmlxList" :key="item.key" :label="item.value" :value="item.key"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="密码">
                                <el-input type="password" v-focus v-model="userInfo.userPwd" placeholder="请输入密码"></el-input>
                            </el-form-item>
                            <el-form-item label="手机号">
                                <el-input type="number" v-focus v-model="userInfo.phoneNo" placeholder="请输入手机号"></el-input>
                            </el-form-item>
                            <el-form-item label="邮箱">
                                <el-input type="text" v-focus v-model="userInfo.email" placeholder="请输入邮箱"></el-input>
                            </el-form-item>
                            <el-form-item label="建设单位">
                                <el-select v-model="userInfo.Company_Id" placeholder="请选择建设单位" style="width:100%">
                                    <el-option v-for="item in companyListSelect" :key="item.id" :label="item.dwmc" :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>

                        </el-col>
                    </el-row>
                    <el-row :gutter="0">
                        <el-form-item class="login-btn-box">
                            <el-col :span="12">
                                <el-button type="primary" size="large" :loading="loading" @click="register">
                                    <span v-if="!loading">注册</span>
                                    <span v-else>正在注册...</span>
                                </el-button>
                            </el-col>
                            <el-col :span="12">
                                <el-link type="primary" href="/#/login" class="fright">已有账号，我要登陆</el-link>
                            </el-col>
                        </el-form-item>
                    </el-row>
                </el-form>
            </div>
        </div>

        <el-footer>
            <p>
                技术支持
                <a href="http://www.hljzztech.com/">黑龙江正则11科技开发有限公司</a>
            </p>
            <p>{{ version }}</p>
        </el-footer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loading: false,
            version: "",
            xmlxList: [],
            companyList: [],
            companyListSelect: [],
            userInfo: {
                userName: "",
                userTrueName: "",
                userPwd: "",
                gender: 0,
                email: "",
                phoneNo: "",
                Company_Id: "",
                xmlx: "6",
            },
        };
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus();
            },
        },
    },
    created() {
        this.http
            .post("/api/Sys_Dictionary/getXmlxDictionary", {})
            .then((result) => {
                this.xmlxList = result.find(
                    (item) => item.dicNo == "gh_xmlx"
                ).data;
            });
        this.http
            .get("/api/Plan_buildcompany/getBuildCompanyList", {})
            .then((result) => {
                this.companyList = result.data;
            });
    },
    mounted() {
        this.version = process.env.VUE_APP_Version;
    },
    destroyed() {},
    methods: {
        getCompany(e) {
            this.companyListSelect = [];
            var ide = this.xmlxList.find((item) => item.key == e);
            this.companyListSelect = this.companyList.filter(
                (item) => item.xmlx == ide.remark
            );
        },
        register() {
            if (this.userInfo.userName.trim() == "") {
                return this.$message.error("请输入用户名");
            }
            if (this.userInfo.userPwd.trim() == "") {
                return this.$message.error("请输入密码");
            }
            if (this.userInfo.userTrueName.trim() == "") {
                return this.$message.error("请输入真实姓名");
            }
            if (this.userInfo.phoneNo.trim() == "") {
                return this.$message.error("请输入手机号");
            }
            if (this.userInfo.xmlx == "") {
                return this.$message.error("请选择项目类型");
            }
            if (this.userInfo.Company_Id == "") {
                return this.$message.error("请选择建设单位");
            }
            this.loading = true;
            console.log(this.userInfo);
            var _this = this;
            this.http
                .post("/api/Sys_User/registUser", this.userInfo, "正在注册....")
                .then((result) => {
                    this.$message(result.message);
                    this.loading = false;
                    if (result.status) {
                        setTimeout(() => {
                            _this.$router.push({
                                path: "/login",
                                params: { r: Math.random() },
                            });
                        }, 1000);
                    }
                });
        },
    },
};
</script>
<style>
/* @import "../assets/css/font-awesome.min.css";
@import "../assets/css/index.css";
@import "../assets/css/style.css"; */
</style>
<style scoped>
.bg-login {
    height: 100vh;
}
#login-box {
    height: 488px;
}
</style>
